<template>
  <DefaultTemplate
    :footer="false"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <el-row :gutter="20" type="flex" justify="center" v-if="!loading">
      <el-col :span="24" :offset="0">
        <el-row>
          <el-col
            :span="24"
            :xs="{ span: 24, offset: 0 }"
            :sm="{ span: 24, offset: 0 }"
            :md="{ span: 18, offset: 3 }"
            :lg="{ span: 18, offset: 3 }"
            :xl="{ span: 12, offset: 6 }"
          >
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form
                  label-position="top"
                  :model="form"
                  ref="ruleForm"
                  :rules="rules"
                  @submit.native.prevent="submitRegis"
                >
                  <h1 class="text-center color-main font-weight-normal mg-b-2">
                    Create User By Role
                  </h1>
                  <el-form-item label="ชื่อ" prop="name">
                    <el-input
                      v-model="form.name"
                      clearable
                      placeholder="กรุณากรอกชื่อ"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="นามสกุล" prop="surname">
                    <el-input
                      v-model="form.surname"
                      clearable
                      placeholder="กรุณากรอกนามสกุล"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="เบอร์โทรศัพท์"
                    prop="phone"
                    class="fix-label"
                  >
                    <el-input
                      id="mobilephone"
                      v-model="form.phone"
                      maxlength="10"
                      @keypress.native="isNumber($event)"
                      placeholder="กรุณากรอกเบอร์โทรศัพท์"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="อีเมล" prop="email">
                    <el-input
                      v-model="form.email"
                      clearable
                      placeholder="กรุณากรอกอีเมล"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="สร้างรหัสผ่าน" prop="newPassword">
                    <el-input
                      v-model="form.newPassword"
                      show-password
                      placeholder="กรุณากรอกรหัสผ่าน"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="ยืนยันรหัสผ่าน" prop="password">
                    <el-input
                      v-model="form.password"
                      show-password
                      placeholder="กรุณากรอกรหัสผ่านยืนยัน"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="Role" prop="role">
                    <el-select
                      filterable
                      clearable
                      v-model="form.role"
                      placeholder="กรุณาเลือก Role"
                      no-match-text="ไม่พบข้อมูลที่ค้นหา"
                    >
                      <el-option
                        v-for="(item, index) in optionsRole"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <div class="flex-between mg-t-4">
                    <el-button round @click="$router.go(-1)">Cancel</el-button>
                    <el-button
                      type="warning"
                      round
                      :loading="loadingBtn"
                      native-type="submit"
                      >Save</el-button
                    >
                  </div>
                </el-form>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่าน"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่านอีกครั้ง"));
      } else if (value !== this.form.newPassword) {
        callback(new Error("รหัสผ่านไม่ตรงกัน"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      loadingBtn: false,
      form: {
        name: "Test30",
        surname: "Level 60",
        email: "level60_30@gmail.com",
        phone: "0899999999",
        newPassword: "123456789",
        password: "123456789",
        role: 60,
      },
      optionsRole: [
        {
          value: 80,
          lebel: "Board",
        },
        {
          value: 60,
          lebel: "Admin",
        },
        {
          value: 50,
          lebel: "Auditor",
        },
      ],
      rules: {
        name: [
          {
            required: true,
            message: "กรุณากรอกชื่อ",
            trigger: ["blur", "change"],
          },
        ],
        surname: [
          {
            required: true,
            message: "กรุณากรอกนามสกุล",
            trigger: ["blur", "change"],
          },
        ],
        email: [
          {
            required: true,
            message: "กรุณากรอกอีเมล",
            trigger: "blur",
          },
          {
            type: "email",
            message: "กรุณากรอกอีเมลให้ถูกต้อง",
            trigger: ["blur", "change"],
          },
        ],
        newPassword: [
          {
            required: true,
            message: "กรุณากรอกรหัสผ่าน",
            trigger: ["blur", "change"],
          },
          {
            min: 8,
            message: "รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร",
            trigger: ["blur", "change"],
          },
          { validator: validatePass, trigger: ["blur", "change"] },
        ],
        password: [
          {
            required: true,
            message: "กรุณากรอกรหัสผ่าน",
            trigger: ["blur", "change"],
          },
          {
            min: 8,
            message: "รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร",
            trigger: ["blur", "change"],
          },
          { validator: validatePass2, trigger: ["blur", "change"] },
        ],
        phone: [
          {
            required: true,
            message: "กรุณากรอกเบอร์โทรศัพท์",
            trigger: ["blur", "change"],
          },
          {
            min: 10,
            message: "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง",
            trigger: ["blur", "change"],
          },
        ],
        role: [
          {
            required: true,
            message: "กรุณากรอก Role",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    submitRegis() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          delete this.form.newPassword;
          // for (let index = 1; index < 30; index++) {
          let obj = {
            ...this.form,
            // name: "Test" + index,
            // email: `level60_` + index + `@gmail.com`,
            key: process.env.VUE_APP_BASE_URL_KEY_ADMIN,
          };
          HTTP.post(`user/create/admin/role`, obj)
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  title: "สมัครสมาชิกสำเร็จ!",
                  type: "success",
                  customClass: "success",
                });
              } else {
                if (res.data.data == "Email is already exists") {
                  this.$message({
                    message: "อีเมลนี้มีผู้ใช้งานแล้ว",
                    type: "error",
                    duration: 4000,
                  });
                }
              }
            })
            .catch((e) => {
              if (e.response.data.status == 422) {
                this.$message({
                  message: "อีเมลหรือรหัสผ่านไม่ถูกต้อง",
                  type: "error",
                  duration: 4000,
                });
              }
            });
          // }
        }
      });
    },
  },
};
</script>
